<template lang="pug">
  .dropdowns
    .shops
      .filter-title {{ $t("inventory_management.shop_name") }}
      AppDropdown.shops-dropdown(
        :followReferenceBoundary="false"
        name="shops-dropdown"
        close-on-select
        searchable
        :loading="shopsLoading"
        :value="shop"
        :items="shopsItems"
        order-direction="keep"
        z-index="10003"
        :placeholder="$t('inventory_management.select_shop')"
        @select="$emit('select-shop', $event)"
        @remove="$emit('select-shop', {})"
      )
    .car-classes
      .filter-title {{ $t("inventory_management.car_class") }}
      AppDropdown.car-class-name(
        v-if="isEmpty(shop)"
        :followReferenceBoundary="false"
        disabled
        z-index="10003"
        :placeholder="$t('inventory_management.select_car_classes')"
        v-b-tooltip.hover
        :title="$t('inventory_management.select_shop_first')"
      )
      AppDropdown.car-class-name(
        v-else
        :followReferenceBoundary="false"
        name="car-class-name"
        z-index="10003"
        checkbox
        batch-select
        multiple
        allow-empty
        close-on-select
        searchable
        :loading="carClassesLoading"
        value-key="id"
        title-key="name"
        :placeholder="$t('inventory_management.select_car_classes')"
        :value="carClasses"
        :items="carClassesItems"
        :is-item-disabled="isItemDisabled"
        order-direction="preorder"
        @select="$emit('select-car-classes', $event)"
        :max-select-count="maxSelectCountCarClass"
        :tips="$t('general_settings.car_class_count_tip')"
      )
</template>

<script>
  //misc
  import { isEmpty } from "lodash-es"
  import withMaxSelectCountCarClass from "@/mixins/withMaxSelectCountCarClass"

  export default {
    props: {
      shop: Object,
      carClasses: Array,
      shopsItems: {
        type: Array,
        default: () => []
      },
      carClassesItems: {
        type: Array,
        default: () => []
      },
      shopsLoading: {
        type: Boolean,
        default: false
      },
      carClassesLoading: {
        type: Boolean,
        default: false
      }
    },

    mixins: [withMaxSelectCountCarClass],

    components: {
      AppDropdown: () => import("@/components/elements/AppDropdown")
    },

    methods: {
      isEmpty,

      isItemDisabled({ selectable }) {
        return !selectable
      }
    }
  }
</script>

<style lang="sass">
  @import "@/assets/styles/variables.sass"

  [name="shops-dropdown"],
  [name="car-class-name"]
    .app-select-items
      max-height: 200px !important
      @media screen and (max-height: 768px)
        max-height: calc(#{$content-height} - 350px)
</style>

<style lang="sass" scoped>
  @import "@/assets/styles/variables.sass"

  .dropdowns
    align-items: center
    display: flex
    justify-content: space-between

    .shops,
    .car-classes
      width: 49%

      .filter-title
        color: $filter-title-color
        font-size: 0.8rem
        margin-left: 8px
</style>
